<template ref="adminUser">
  <div>
      <header-admin-pages
        title="Почты для рассылки"
        :counter="$store.getters.getEmailSubscription.length"
      />
    <div class="content-sb filters">
      <p>Ссылка на документ</p>
      <a :href="`${$store.getters.getUrlApi}api/admin/mailing/file`">скачать</a>
    </div>
      <main>
        <table>
          <tr>
            <td><p>E-mail</p></td>
            <td class="drop-icon"></td>
          </tr>
          <tr v-for="item in $store.getters.getEmailSubscription" :key="`country${item.id}`">
            <td><p>{{ item.email }}</p></td>
            <td class="drop-icon" @click="clickItem(item)" :idItem="item.id">
              <p>x</p>
            </td>
          </tr>
        </table>
      </main>
  </div>
</template>

<script>
import HeaderAdminPages from '@/components/Admin/HeaderAdminPages'
export default {
  name: 'EmailSubscription',
  methods: {
    clickItem (value) {
      const consent = confirm('Вы уверены, чтобы хотите удалить почту ?')
      if (consent) {
        this.$store.dispatch('deleteEmailSubscription', value.id)
      } else {
        this.$noty.warning('Удаление отменено')
      }
    }
  },
  mounted () {
    this.$store.dispatch('setEmailSubscription')
  },
  computed: {
    test () {
      return this.$store.getters.getEmailSubscription
    }
  },
  components: {
    HeaderAdminPages
  }
}
</script>

<style scoped lang="scss">
.filters{
  margin-top: 1.37rem;
  padding-bottom: 1.37rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid #D5D6D9;
}
.input{
  width: 32%;
  margin-bottom: 1.25rem;
}
.filters-reset {
  background: none;
  font-size: 0.75rem;
  color: #003E78;
  opacity: 0.6;
  margin-right: 1.37rem;
  cursor: pointer;
}
table{
  margin-top: 2rem;
  border-spacing: 0;
  border-collapse: collapse;

  tr{
    padding: 0;
    border-bottom: 1px solid #D5D6D9;
  }
}
td {
  max-width: 23%;
}
tr {
  p {
    font-size: 0.812rem;
    line-height: 154%;
    color: #5E686C;
  }
}
tr {
  a {
    font-size: 0.812rem;
    line-height: 154%;
    color: #246CB7;
  }
}
.drop-icon {
  width: 3rem;
  cursor: pointer;
}
</style>
